import { URL_PREFIXES } from 'app/constants';
import { OnboardingLayout } from 'app/layouts';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const onboardingRoutes = {
	path: URL_PREFIXES.ONBOARDING,
	component: OnboardingLayout,
	routes: [
		{
			path: `${URL_PREFIXES.ONBOARDING}`,
			exact: true,
			component: lazy(() => import('features/onboarding')),
		},
		{
			component: () => <Redirect to="/errors/error-404" />,
		},
	],
};

export default onboardingRoutes;
