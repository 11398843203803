import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from 'app/store/axiosService';
import { genericRefreshToast } from '../toast';
import { setProgress } from './progress.slice';

export const fetchModuleTestById = createAsyncThunk('moduleTest/fetchTestById', async (testId) => {
	try {
		const response = await axiosService.instance.get(`/test/${testId}`);
		return response.data;
	} catch (error) {
		genericRefreshToast();
		throw error;
	}
});

export const submitTest = createAsyncThunk(
	'moduleTest/submitTest',
	async ({ testId, data, setSubmitting }, thunkAPI) => {
		try {
			const response = await axiosService.instance.post(
				`/submit-module-test/${testId}`,
				data
			);
			thunkAPI.dispatch(setProgress(response.data.progress));
			return response.data;
		} catch (error) {
			genericRefreshToast();
			throw error;
		} finally {
			setSubmitting(false);
		}
	}
);

export const moduleTestsSlice = createSlice({
	name: 'moduleTests',
	initialState: {
		data: {},
		testInProgress: null,
	},
	reducers: {
		cleanUpTestInProgress(state) {
			state.testInProgress = null;
		},
		cleanUpSubmittedModuleTestById(state, action) {
			state.data[action.payload] = null;
		},
	},
	extraReducers: {
		[fetchModuleTestById.fulfilled]: (state, action) => {
			state.data[action.payload.id] = action.payload;
		},
		[submitTest.fulfilled]: (state, action) => {
			state.testInProgress = {
				testFinished: true,
				score: action.payload.score,
				success: action.payload.success,
			};
		},
		[submitTest.rejected]: (state) => {
			state.testInProgress = {
				testFinished: true,
				error: true,
			};
		},
	},
});

export const { cleanUpTestInProgress, cleanUpSubmittedModuleTestById } = moduleTestsSlice.actions;

export const selectModuleTestById = (id) => (state) => ({
	data: state.moduleTests.data[id],
	isDataAvailable: !!state.moduleTests.data[id],
});

export const selectTestInProgress = (state) => state.moduleTests.testInProgress;

export default moduleTestsSlice.reducer;
