import palette from '../palette';

export default {
	root: {
		color: '#818181',
		fontFamily: 'Work Sans',
		fontWeight: 'bold',
		width: '120%',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		lineHeight: '1.5em',
		textOverflow: 'ellipsis',
		'&$focused': {
			color: palette.primary.main,
		},
	},
};
