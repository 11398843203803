import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from 'app/store/axiosService';
import { genericRefreshToast } from '../toast';

export const fetchLessonContentById = createAsyncThunk(
	'lesson/fetchLessonById',
	async (lessonId) => {
		try {
			const response = await axiosService.instance.get(`/lesson/${lessonId}`);
			return response.data;
		} catch (error) {
			genericRefreshToast();
			throw error;
		}
	}
);

export const lessonContentsSlice = createSlice({
	name: 'lessonContents',
	initialState: {
		data: {},
	},
	reducers: {},
	extraReducers: {
		[fetchLessonContentById.fulfilled]: (state, action) => {
			state.data[action.payload.id] = action.payload;
		},
	},
});

export const selectLessonContentById = (id) => (state) => ({
	data: state.lessonContents.data[id],
	isDataAvailable: !!state.lessonContents.data[id],
});

export default lessonContentsSlice.reducer;
