import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from 'app/store/axiosService';
import { RootState } from 'app/store/store';
import type { Event, EventCategory } from 'features/events/type/Event.type';
import { genericRefreshToast } from '../../toast';
import { updateEvent } from './manageEventThunks';

const baseUrl = '/event';

type EventPagination = {
	totalCount: number | null;
	pageCount: number | null;
	pageNumber: number;
};

export type EventFilters = {
	search: string;
	categories: EventCategory[] | null;
};

type EventsState = {
	isPending: boolean;
	events: Event[] | null;
	pagination: EventPagination;
	filters: EventFilters;
};

const initialState: EventsState = {
	isPending: false,
	events: null,
	pagination: {
		totalCount: null,
		pageCount: null,
		pageNumber: 1,
	},
	filters: {
		search: '',
		categories: null,
	},
};

type GetEventsParams = {
	pageNumber?: number;
	filters?: EventFilters;
};
export const getEvents = createAsyncThunk(
	'events/getEvents',
	async (params: GetEventsParams = {}, thunkApi) => {
		const { getState, dispatch } = thunkApi;
		const { pageNumber } = params;

		const rootState = getState() as RootState;

		const state = rootState.event;
		const filters = params.filters ?? state.filters;

		// prevent multiple search requests
		if (state.isPending) {
			return null;
		}

		// if action triggered by filter update, save it in store
		// on page change, filters are sent from store
		if (params.filters) {
			dispatch(updateCachedFilters(params.filters));
		}

		// block further searches until action finishes
		dispatch(startPending());

		try {
			const search = filters?.search ?? null;
			const { categories } = filters;

			const requestBody = {
				categories,
				search,
				pageNumber: pageNumber ?? state.pagination.pageNumber,
			};

			const response = await axiosService.instance.post(`${baseUrl}/find-all`, requestBody);

			const {
				events,
				pagination,
			}: {
				events: Event[];
				pagination: EventPagination;
			} = response.data;

			return {
				events,
				pagination,
			};
		} catch (error) {
			genericRefreshToast();
			throw error;
		}
	}
);

export const eventsSlice = createSlice({
	name: 'events',
	initialState,
	reducers: {
		startPending(state) {
			state.isPending = true;
		},
		updateCachedFilters(state, action) {
			state.filters = action.payload;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(getEvents.fulfilled, (state, action) => {
			// cancelled request
			if (action.payload === null) {
				return;
			}

			state.isPending = false;

			const { events, pagination } = action.payload;

			state.pagination = pagination;
			state.events = events;
		});
		builder.addCase(updateEvent.fulfilled, (state, action) => {
			state.events = (state.events as Event[]).map((item) => {
				if (item._id === action.payload._id) {
					return action.payload;
				}
				return item;
			});
		});
	},
});

export const { startPending, updateCachedFilters } = eventsSlice.actions;

export const selectEventList = (state: RootState) => state.event.events;

export const selectEventPaginationInfo = (state: RootState) => state.event.pagination;

export const selectEventResultSize = (state: RootState) =>
	state.event.pagination.totalCount ?? null;

export const selectEventIsPending = (state: RootState) => state.event.isPending;

export const selectIsEventsFiltered = (state: RootState) => {
	const { search, categories } = state.event.filters;
	return search !== '' || categories;
};

export default eventsSlice.reducer;
