export default {
	title: 'Profil beállítások',
	subtitle: 'Feltölthetsz profilképet vagy megváltoztathatod a jelszavadat.',
	profile: {
		title: 'Profil',
		profilePicture: 'Profilkép módosítása',
		changePicture: 'Profilkép megváltoztatása',
	},
	changePassword: {
		title: 'Jelszó változtatás',
	},
	personality: {
		title: 'Személyiség',
		changePersonality: 'Személyiség megváltoztatása',
		successMessage: 'Sikeres változtatás!',
		errorMessage: 'Sikertelen változtatás!',
	},
	company: {
		title: 'Vállalkozási adatok',
	},
};
