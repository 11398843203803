import palette from '../palette';

export default {
	root: {
		marginTop: 4,
		borderRadius: 8,
	},
	notchedOutline: {
		borderColor: palette.gray[100],
	},
	input: {
		padding: '11px 14px',
		borderRadius: 8,
	},
};
