export default {
	header: {
		welcome: 'Üdv, ',
		modulesNotCompleted:
			'Nézd meg eredményeidet az irányítópulton, ha pedig készen állsz haladj tovább a tananyagokkal!',
		allModulesCompleted: 'Gratulálunk a képzés elvégzéséhez!',
		secondSemester:
			'Ebben a félévben is vár rád 5 modul. Na meg a csapat, mentorok és szuper lehetőségek! Sok sikert!',
		universitySubtitle:
			'Köszöntünk a Hungarian Startup University Programban. Kövesd egyetemed és hallgatóid eredményeit!',
		officeSubtitle: 'Üdv,',
	},
	performance: {
		title: 'Teljesítmény',
		suggestedGrade: 'Javasolt eredményed',
		text: {
			start: 'Végső eredményed',
			end: ', ne felejtsd el a one-pageredet elküldeni a tantárgy teljesítéséhez.',
		},
		suggestedText:
			'Amennyiben maradt még próbálkozási lehetőséged, javíthatsz a jegyeden a félév zárásáig.',
		grades: {
			excellent: 'jeles',
			good: 'jó',
			mediocre: 'közepes',
			poor: 'elégséges',
			unacceptable: 'elégtelen',
		},
		sum: 'Összesen',
		moduleProgress: {
			completed: 'Teljesítve',
			notCompleted: 'Nincs teljesítve',
			notAvailable: 'Nem elérhető',
		},
		body: {
			start: 'Eddig',
			points: 'pontot',
			middle: 'gyűjtött',
			end: 'modul teljesítésével.',
		},
	},
	progress: {
		title: 'Pontok',
		modules: 'Teljesített modulok',
		onePager: 'One-pager beküldése',
		progressReport: 'Progress report beküldése',
		send: 'Küldés',
		moreInfo: 'További információ',
		chart: {
			completed: 'Megszerzett pontok',
			remaining: 'Elérhető pontok',
		},
	},
	mentor: {
		headline: 'Mentorra van szükséged?',
		subtitle: 'Jól jönne egy kis segítség? Összekötünk a mentoroddal!',
		contact: 'Kapcsolatfelvétel',
	},
	personality: {
		innovationLevel: 'Vállalkozásod innovációs szintje',
		text: {
			start: 'Te egy született',
			end: 'vagy',
		},
		ujito: 'Újító',
		maradi: 'Maradi',
		koveto: 'Követő',
		learnMore: 'Tudj meg többet',
	},
	userStatistics: {
		completedExams: 'Teljesített vizsgák',
		logins: 'Belépések száma',
		suggestions: 'Javaslatok száma',
	},
	scoresTable: {
		organization: 'Vállalkozás neve',
		students: 'Tanulók',
		student: 'Kitöltő neve',
		score: 'Pontszám',
		average: 'Átlag',
	},
	statistics: {
		student: {
			title: 'Vállalkozói Ranglista',
		},
		university: {
			title: 'Egyetemi Ranglista',
		},
		office: {
			title: 'Egyetemi adatok - {{semester}}. félév',
			all: 'Összes egyetem',
		},
		progress: {
			title: 'Teszt kitöltések száma',
			text: 'modul teszt',
		},
		innovation: {
			title: 'Innovációs tesztek eredményei',
			all: 'Összes kitöltés száma',
		},
		registration: {
			title: 'Regisztrációs és projekt adatok',
			texts: {
				email: 'Kiküldött e-mailek száma',
				registerCount: 'Regisztráltak száma',
			},
		},
		course: {
			title: 'Kurzus adatok',
			metrics: {
				finish: 'Teljes kurzust elvégzők száma',
				score: 'Legmagasabb elért pontszám',
				average: 'Összesített átlagpontszám',
				feedback: 'Beérkezett javaslatok száma',
			},
			suffix: {
				company: 'diák',
				score: 'pont',
				count: 'db',
			},
		},
	},
	certificate: {
		title: {
			success: 'Gratulálunk a program sikeres teljesítéséhez!',
			notAllModulesFinished: 'Az oklevél letöltéséhez először végezze el a tananyagokat!',
			minPercentNotReached: 'Még nem érte el a 80%-ot.',
		},
		subtitle: {
			success: 'Töltse le oklevelét!',
			notAllModulesFinished:
				'Amíg nem teljesítette az összes modulvizsgát az oklevelet nem tudja letölteni!',
			minPercentNotReached:
				'Próbálja meg a teszteket 80% vagy annál jobban teljesíteni, hogy megkapja a képzés hivatalos oklevelét.',
		},
		downloadBtnText: 'Oklevél letöltése',
	},
};
