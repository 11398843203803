import { InnovationType } from 'features/personality-test/innovation.type';

export const MODULE_PROGRESS_STATUS = {
	INACTIVE: 'INACTIVE',
	ACTIVE: 'ACTIVE',
	COMPLETED: 'COMPLETED',
};

export const FALLBACK_IMG_URL = './images/default_profile_picture.png';

export const innovationTypes = [InnovationType.MARADI, InnovationType.KOVETO, InnovationType.UJITO];
export const innovationTypesReverseOrder = Array.from(innovationTypes).reverse();

export const URL_PREFIXES = {
	AUTH: '/auth',
	PERSONALITY: '/personality-test',
	ONBOARDING: '/onboard',
	STUDENT: '/student',
	OFFICE: '/office',
} as const;

export const USER_TYPES = {
	STUDENT: 'STUDENT',
	OFFICE: 'OFFICE',
};

export const LAYOUT_TYPES = {
	GUEST: 'GUEST',
	PERSONALITY: 'PERSONALITY',
	ONBOARDING: 'ONBOARDING',
	ENROLLED: 'ENROLLED',
	OFFICE: 'OFFICE',
} as const;

export const FILE_MAX_NUMBER = {
	corporateProblem: 3,
};
