import { URL_PREFIXES } from 'app/constants';
import { EnrolledLayout } from 'app/layouts/User';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const { STUDENT } = URL_PREFIXES;

const studentRoutes = {
	path: STUDENT,
	component: EnrolledLayout,
	routes: [
		{
			path: `${STUDENT}`,
			exact: true,
			component: lazy(() => import('features/dashboard/student')),
		},
		{
			path: `${STUDENT}/news`,
			exact: true,
			component: lazy(() => import('features/news')),
		},
		// user can view personality detail page after taking the test too
		// but cannot retake test
		{
			path: `${STUDENT}/detail/:personality`,
			exact: true,
			component: lazy(() => import('features/personality-test/detail')),
		},
		{
			path: `${STUDENT}/lessons/:id`,
			exact: true,
			component: lazy(() => import('features/lesson/lesson')),
		},
		{
			path: `${STUDENT}/modules`,
			exact: true,
			component: lazy(() => import('features/progress/progress')),
		},
		{
			path: `${STUDENT}/settings`,
			exact: true,
			component: lazy(() => import('features/settings')),
		},
		{
			path: `${STUDENT}/tests/:id`,
			exact: true,
			component: lazy(() => import('features/module-test/module-test')),
		},
		{
			path: `${STUDENT}/events`,
			exact: true,
			component: lazy(() => import('features/events')),
		},
		{
			path: `${STUDENT}/events/:eventId`,
			exact: true,
			component: lazy(() => import('features/event-details')),
		},
		{
			path: `${STUDENT}/help`,
			exact: true,
			component: lazy(() => import('features/help')),
		},
		{
			path: `${STUDENT}/onboard`,
			exact: true,
			component: lazy(() => import('features/onboarding')),
		},
		{
			path: `${STUDENT}/partners`,
			exact: true,
			component: lazy(() => import('features/partners')),
		},
		{
			component: () => <Redirect to="/errors/error-404" />,
		},
	],
};

export default studentRoutes;
