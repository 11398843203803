import { ThemeProvider } from '@material-ui/core/styles';
import ScrollReset from 'app/components/ScrollReset';
import routes from 'app/routes';
import { fetchUser, getUser } from 'app/store/slices/auth.slice';
import TestEnvIndicator from 'app/utils/test-env-indicator';
import 'cropperjs/dist/cropper.css';
import React, { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import axiosInstance from './app/store/axiosService';
import { persistor, store } from './app/store/store';
import theme from './app/theme';

function App() {
	const dispatch = useDispatch();
	const user = useSelector(getUser);

	useEffect(() => {
		dispatch(fetchUser());
	}, [dispatch]);

	// make sure guards handle urls properly
	if (user === null) {
		return null;
	}

	return (
		<Router>
			<ToastContainer limit={1} />
			<ScrollReset />
			{user && <TestEnvIndicator />}
			{renderRoutes(routes)}
		</Router>
	);
}

function RootApp() {
	return (
		<Provider store={store}>
			<PersistGate
				loading={null}
				persistor={persistor}
				onBeforeLift={() => {
					const {
						auth: { token },
					} = store.getState();

					if (token) {
						axiosInstance.refreshRequestHandler(token);
					}
				}}
			>
				<ThemeProvider theme={theme}>
					<App />
				</ThemeProvider>
			</PersistGate>
		</Provider>
	);
}

export default RootApp;
