import authRoutes from 'features/auth/auth.routes';
import onboardingRoutes from 'features/onboarding/onboarding.routes';
import personalityTestRoutes from 'features/personality-test/personality-test.routes';
import emptyRoutes from './empty.routes';
import errorRoutes from './error.routes';
import { officeRoutes, studentRoutes } from './user';

const routes = [
	authRoutes,
	onboardingRoutes,
	personalityTestRoutes,
	errorRoutes,
	studentRoutes,
	officeRoutes,
	emptyRoutes, // redirects to login, must be last entry
];

export default routes;
