export default {
	section: {
		extras: 'Kapcsolódó interaktív tartalmak',
		videos: 'Videós tartalmak',
		podcasts: 'Podcastok',
		relatedLinks: 'Csatolmányok',
	},
	navigation: {
		previousLesson: 'Előző',
		nextLesson: 'Következő',
		startTest: 'Vizsga indítása',
	},
	suggestion: {
		callToAction: 'Írd meg javaslatod a tananyaggal kapcsolatban!',
		placeholder: 'Azt javaslom, hogy...',
		send: 'Küldés',
		thanks: 'Köszönjük a visszajelzést!',
	},
};
