import palette from '../palette';

export default {
	root: {
		border: `2px solid ${palette.gray[100]}`,
		backgroundColor: 'white',
		borderRadius: 8,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		'&:hover': {
			backgroundColor: 'white',
		},
		'&$focused': {
			borderColor: palette.primary.main,
			backgroundColor: 'white',
		},
		'&$error': {
			borderColor: palette.error.main,
			backgroundColor: 'white',
		},
	},
	input: {
		'&::placeholder': {
			opacity: 0.7,
			color: palette.gray[700],
		},
	},
};
