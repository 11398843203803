import palette from './palette';

export default {
	fontFamily: 'Work Sans',
	h1: {
		color: palette.secondary[600],
		fontFamily: 'Work Sans',
		fontWeight: 700,
		fontSize: '30px',
		lineHeight: '35px',
	},
	h2: {
		color: palette.secondary[700],
		fontFamily: 'Work Sans',
		fontWeight: 700,
		fontSize: '24px',
		lineHeight: '28px',
	},
	h3: {
		color: palette.secondary[600],
		fontFamily: 'Work Sans',
		fontWeight: 600,
		fontSize: '22px',
		lineHeight: '26px',
	},
	h4: {
		color: palette.secondary[700],
		fontFamily: 'Work Sans',
		fontWeight: 600,
		fontSize: '18px',
		lineHeight: '21px',
	},
	h5: {
		color: palette.secondary[700],
		fontFamily: 'Work Sans',
		fontWeight: 600,
		fontSize: '16px',
		lineHeight: '20px',
	},
	body1: {
		color: palette.text.paragraph,
		fontFamily: 'Work Sans',
		fontWeight: 400,
		fontSize: '16px',
		lineHeight: '24px',
	},
	caption: {
		color: palette.text.caption,
		fontFamily: 'Work Sans',
		fontWeight: 400,
		fontSize: '12px',
		lineHeight: '18px',
		textTransform: 'uppercase',
		letterSpacing: '0.5px',
		display: 'block',
	},
	overline: {
		color: '#A6A6A6',
		fontFamily: 'Work Sans',
		fontWeight: 500,
		fontSize: '12px',
		lineHeight: '18px',
	},
	button: {
		color: palette.text.button,
		fontFamily: 'Work Sans',
		fontWeight: 600,
		fontSize: '14px',
		lineHeight: '16px',
		letterSpacing: '0.5px',
		textTransform: 'uppercase',
	},
};
