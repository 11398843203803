import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
	FLUSH,
	PAUSE,
	PERSIST,
	persistReducer,
	persistStore,
	PURGE,
	REGISTER,
	REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
	authReducer,
	contextReducer,
	eventDetailReducer,
	eventReducer,
	findEntrepreneurReducer,
	lessonContentsReducer,
	loaderReducer,
	moduleTestReducer,
	newsReducer,
	personalityTestReducer,
	progressReducer,
	statisticsReducer,
	suggestionReducer,
} from './slices';

const authPersistConfig = {
	key: 'evita',
	storage,
	whitelist: ['token'],
};

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);

const store = configureStore({
	reducer: {
		auth: persistedAuthReducer,
		loader: loaderReducer,
		progress: progressReducer,
		statistics: statisticsReducer,
		moduleTests: moduleTestReducer,
		lessonContents: lessonContentsReducer,
		personalityTest: personalityTestReducer,
		suggestion: suggestionReducer,
		context: contextReducer,
		findEntrepreneur: findEntrepreneurReducer,
		news: newsReducer,
		event: eventReducer,
		eventDetail: eventDetailReducer,
	},
	middleware: getDefaultMiddleware({
		serializableCheck: {
			ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
		},
	}),
});

const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export { store, persistor };

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
