import { createTheme } from '@material-ui/core';
import overrides from './overrides';
import palette from './palette';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
	palette,
	typography,
	overrides,
	shadows,
});

export default theme;
