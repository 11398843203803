import { getContext } from 'app/store/slices/context.slice';
import React from 'react';
import { useSelector } from 'react-redux';

export default function TestEnvIndicator() {
	const context = useSelector(getContext);

	return (
		(context.TEST_WARNING === 'enabled' && (
			<div
				style={{
					position: 'fixed',
					bottom: '0px',
					width: '100%',
					zIndex: 999,
					textAlign: 'center',
					color: 'white',
					backgroundColor: 'firebrick',
					fontSize: '30px',
				}}
			>
				TEST ENVIRONMENT!
			</div>
		)) ||
		null
	);
}
