import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from 'app/store/axiosService';
import { genericRefreshToast } from '../toast';

export const fetchSuggestions = createAsyncThunk('suggestion/fetchSuggestions', async () => {
	try {
		const response = await axiosService.instance.get(`/suggestion`);
		return response.data;
	} catch (error) {
		genericRefreshToast();
		throw error;
	}
});

export const postSuggestion = createAsyncThunk(
	'suggestion/postSuggestion',
	async ({ lesson, comment }) => {
		try {
			const response = await axiosService.instance.post(`/suggestion`, { lesson, comment });
			return response.data;
		} catch (error) {
			genericRefreshToast();
			throw error;
		}
	}
);

export const suggestionSlice = createSlice({
	name: 'suggestion',
	initialState: {
		data: [],
		isDataAvailable: false,
	},
	reducers: {},
	extraReducers: {
		[fetchSuggestions.fulfilled]: (state, action) => {
			state.data = action.payload;
			state.isDataAvailable = true;
		},
		[postSuggestion.fulfilled]: (state, action) => {
			state.data.push(action.payload);
			state.isDataAvailable = true;
		},
	},
});

export const getSuggestions = ({ suggestion: { data, isDataAvailable } }) => ({
	data,
	isDataAvailable,
});

export default suggestionSlice.reducer;
