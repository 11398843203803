export default {
	title: 'Hírfolyam',
	subtitle: 'Tölts fel új híreket, szerkessz meglévőket vagy töröld őket, ha valami félrement.',
	addNews: {
		title: 'Szeretnél egy fontos információt megosztani a felhasználókkal?',
		subtitle: 'Kattintson az "új hír feltöltése" gombra!',
		publishButton: 'Új hír feltöltése',
	},
	noNews: {
		title: 'Jelenleg nincsenek hírek a hírfolyamban',
		subtitle: 'Látogass vissza később!',
	},
	deleteDialog: {
		title: 'Biztos törölni szeretnéd a hírt?',
		subtitle: 'A "hír törlése" gombra kattintva véglegesen törli ezt a hírt.',
		submitButton: 'Hír törlése',
	},
	dialog: {
		subtitle:
			'Tölts fel releváns, a szakmai programokhoz, új partnerségekhez, változásokhoz kapcsolódó információkat.',
		newsDataFormGroupTitle: 'Hír adatai',
		newsTitle: 'Hír címe',
		newsContent: 'Hír szövege',
		newsPicture: 'Kép feltöltése (opcionális)',
		newsPictureFieldTitle: 'A hírhez tartozó kép',
		newsPictureFieldSubtitle: 'Kattintson ide vagy húzza ide a képet a feltöltéshez!',
		newsPictureFormatText: 'Feltölthető formátumok:',
	},
	createDialog: {
		title: 'Új hír küldése',
		submitButton: 'Hír közzététele',
	},
	updateDialog: {
		title: 'Hír szerkesztése',
		submitButton: 'Módosítások publikálás',
	},
	toast: {
		addError: 'A hír feltöltése nem sikerült :(',
		addSuccess: 'A hír feltöltése sikerült',
		updateError: 'A hír módosítása nem sikerült',
		updateSuccess: 'A hír módosítása sikerült',
		deleteError: 'A hír törlése nem sikerült',
		deleteSuccess: 'A hír törlése sikerült',
	},
	rolesPlural: {
		student: 'Diákok',
		university: 'Tantárgyfelelősök',
		mentor: 'Mentorok',
	},
	validation: {
		checkboxGroupNoRolesChosen: 'Legalább egy felhasználó csoportot kikell választani',
	},
};
