export default {
	fields: {
		firstName: 'Keresztnév',
		lastName: 'Vezetéknév',
		email: 'E-mail cím',
		postcode: 'Irányítószám',
		country: 'Ország',
		city: 'Város',
		birthyear: 'Születési év',
		gender: 'Nem',
		password: 'Jelszó',
		passwordVerification: 'Jelszó megerősítése',
		currentPassword: 'Jelenlegi jelszó',
		newPassword: 'Új jelszó',
		newPasswordVerification: 'Új jelszó megerősítése',
		username: 'Felhasználónév',
	},
	buttons: {
		login: 'Belépés',
	},
	pages: {
		login: {
			title: 'Bejelentkezés',
			subtitle:
				'A regisztrációkor megadott e-mail cím és jelszó segítségével jelentkezzen be a platformra!',
			noProfile: 'Még nincsen fiókod?',
			signInButton: 'Bejelentkezés',
			forgotPassword: 'Elfejeltetted a jelszavad?',
			wrongCredentials: 'E-mail és jelszó páros nem megfelelő',
			networkError: 'Hiba a hálózati hozzáférésben. Ellenőrizd az internet kapcsolatot!',
			otherError: 'Hiba történt. Próbálkozz újra később!',
		},
		forgotPassword: {
			title: 'Elfelejtett jelszó',
			subtitle: 'Add meg e-mail címed és könnyedén megadhatsz egy új jelszót.',
			successHeader: 'E-mailt küldtünk a megadott e-mail címre!',
			successDescription:
				'Amennyiben regisztráltál a megadott e-mail címmel, emailt kapsz a további lépésekről.',
			resetPassword: 'Jelszó visszaállítása',
		},
		changePassword: {
			title: 'Jelszó módosítása',
			subtitle: 'A jelszó módosításához kérem töltse ki az alábbi szövegmezőket.',
			editPassword: 'Jelszó módosítás',
			successHeader: 'Jelszó sikeresen módosítva!',
			successDescription: 'Most már be tud jelentkezni az új jelszóval!',
			failHeader: 'Sikertelen módosítás!',
			failDescription: 'Ez a link már lejárt.',
			failedButtonLabel: 'Vissza a főoldalra',
		},
		register: {
			title: 'Regisztráció a programra',
			subtitle:
				'Töltse ki a mezőket adataival, fogadja el a felhasználói feltételeket, majd kattintson a "Regisztráció" gombra.',
			userExists: 'Az e-mail cím már használatban van.',
			checkboxMail:
				'Beleegyezek, hogy az Evita felület üzemeltetője időnként hírlevelet küldjön a szolgáltatással kapcsolatban.',
			checkboxPrivacyTOS:
				'A felhasználói fiók regisztrálásával elfogadod az Evita <0>adatvédelmi tájékoztatóját</0>.',
			registerAction: 'Regisztrálok',
			successHeader: 'E-mail cím megerősítése!',
			successDescription:
				'Egy levelet küldtünk az e-mail címedre, amiben meg kell erősítened a jelszavad!',
			successButtonLabel: 'Rendben',
			usernameNote: 'Ez a név fog megjelenni a publikus statisztikák között',
			tokenError: 'Érvénytelen reCAPTCHA validálás.',
		},
		registerOffice: {
			personalFields: 'Személyes adatok',
			passwordFields: 'Jelszó',
			successHeader: 'Sikeres regisztráció',
			successDescription: 'Üdvözöljük az Evitán, most már be tud jelentkezni.',
			failHeader: 'Sikertelen regisztráció!',
			failDescription: 'Ez a link már lejárt.',
			failedButtonLabel: 'Vissza a főoldalra',
		},
		verifyUser: {
			successHeader: 'Sikeres regisztráció',
			successDescription: 'Üdvözöljük az Evitán, most már be tud jelentkezni.',
			successButtonLabel: 'Rendben',
			failHeader: 'Sikertelen regisztráció',
			failDescription: 'A  link már lejárt.',
			failedButtonLabel: 'Vissza a főoldalra',
		},
	},
};
