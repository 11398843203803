import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18n from 'app/i18n';
import axiosService from '../axiosService';
import { errorToast, genericRefreshToast, successToast } from '../toast';

export const fetchNews = createAsyncThunk(
	'news/fetchNews',
	async ({ from, count, setAllowLoadMore }) => {
		try {
			const { data } = await axiosService.instance.get(
				`/news/more?from=${from}&count=${count}`
			);
			if (data.length !== 0) {
				setAllowLoadMore(true);
			}
			return data;
		} catch (error) {
			genericRefreshToast();
			throw error;
		}
	}
);

export const addNews = createAsyncThunk('news/uploadNews', async ({ formData, onSuccess }) => {
	try {
		const { data } = await axiosService.instance.post('/news', formData);
		successToast(i18n.t('news.toast.addSuccess'));
		onSuccess();
		return data;
	} catch (error) {
		errorToast(i18n.t('news.toast.addError'));
		throw error;
	}
});

export const updateNews = createAsyncThunk(
	'news/updateNews',
	async ({ id, formData, onSuccess }) => {
		try {
			const { data } = await axiosService.instance.put(`/news/${id}`, formData);
			successToast(i18n.t('news.toast.updateSuccess'));
			onSuccess();
			return data;
		} catch (error) {
			errorToast(i18n.t('news.toast.updateError'));
			throw error;
		}
	}
);

export const deleteNews = createAsyncThunk('news/deleteNews', async ({ id, onSuccess }) => {
	try {
		const { data } = await axiosService.instance.delete(`news/${id}`);
		successToast(i18n.t('news.toast.deleteSuccess'));
		onSuccess();
		return data;
	} catch (error) {
		errorToast(i18n.t('news.toast.deleteError'));
		throw error;
	}
});

export const newsSlice = createSlice({
	name: 'news',
	initialState: {
		isLoading: false,
		data: [],
	},
	extraReducers: {
		[fetchNews.pending]: (state) => {
			state.isLoading = true;
		},
		[fetchNews.fulfilled]: (state, action) => {
			const news = action.payload;
			state.data = state.data.concat(news);
			state.isLoading = false;
		},
		[addNews.fulfilled]: (state, action) => {
			const added = action.payload;
			state.data = [added].concat(state.data);
		},
		[updateNews.fulfilled]: (state, action) => {
			const updated = action.payload;
			const newData = state.data.map((news) => (news._id === updated._id ? updated : news));
			state.data = newData;
		},
		[deleteNews.fulfilled]: (state, action) => {
			const { id, ok } = action.payload;
			if (ok) {
				state.data = state.data.filter((news) => news._id !== id);
			}
		},
	},
});

export const selectNews = ({ news: { data, isLoading } }) => ({ data, isLoading });

export default newsSlice.reducer;
