export default {
	result: {
		title: 'Modulzáró vizsgaeredmény',
		error: 'Hiba történt :(',
		back: 'Rendben',
		retry: 'Újrapróbálom',
		congratulations: 'Gratulálunk, egy lépéssel közelebb vagy a sikerhez.',
		score: '<grey>Megszerzett pontok száma: <primaryColor>{{score}} pont</primaryColor></grey>',
		failure: 'Sajnos ez most nem sikerült!',
		successDescription:
			'Szép munka, egy lépéssel közelebb kerültél a felelős szülői léthez. Nagyon örülünk, hogy velünk tartasz, biztatunk, hogy folytasd tovább és akár találkozzunk a csatlakozó esményeken is!.',
		failureDescription:
			'Csak semmi pánik, ez csak egy visszajelzés számodra az elsajátított tananyagokkal kapcsolatban. Amennyiben elégedetlen lennél az eredménnyel, térj vissza a témakörökhöz és utána fuss neki még egyszer.',
		failureDescription2:
			'A modulok gombra kattintva folytathatod a tanulást a következő tananyaggal, a vizsgát később ismét meg lehet próbálni.',
	},
	header: {
		title: 'VIZSGA',
	},
	navigation: {
		next: 'Következő',
		back: 'Vissza',
		submit: 'Leadás',
	},
	errors: {
		semesterEnded: 'A félév véget ért.',
		semesterEndedDescription:
			'Eredményeidet és ajánlott jegyedet megtekintheted az irányítópulton.',
		moduleInactive: 'A vizsgához tartozó modult még nem oldottad fel.',
		moduleInactiveDescription:
			'De ne aggódj, mert az ezt megelőző modulok tesztjének kitöltésével könnyedén feloldhatod. Sok sikert!',
		outOfAttempts: 'Elfogytak a próbálkozásaid.',
		outOfAttemptsDescription:
			'Sajnos ez a teszt most nem jött össze. De fel a fejjel! A megszerzett pontjaid így is beleszámítanak az ajánlott jegyedbe. Hogy ne búslakodj, feloldottuk neked a következő modult. Vágj bele most!',
	},
	confirmation: {
		areYouSure: 'Biztosan le szeretnéd adni a tesztet?',
		notAllAnsweredDescription: 'Nekünk úgy tűnik, hogy maradtak még megválaszolatlan kérdései.',
		description:
			'Ha nem vagy biztos a válaszaidban, ellenőrizd őket, vagy kattints a "Leadás most" gombra a véglegesítéshez. Ne felejtsd el, a vizsga újbóli kitöltésének eredménye felülírja az ehhez a vizsgához tartozó már korábbi eredményedet!',
		decline: 'Vissza',
		confirm: 'Leadás most',
	},
};
