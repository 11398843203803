import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from 'app/store/axiosService';
import { genericRefreshToast } from '../toast';
import { updatePersonality } from './auth.slice';
import { setProgress } from './progress.slice';

export const fetchTestItems = createAsyncThunk('fetchTestItems', async () => {
	try {
		const { data: testItems } = await axiosService.instance.get('/personality-test');
		return { testItems };
	} catch (error) {
		genericRefreshToast();
		throw error;
	}
});

export const publishTestAnswers = createAsyncThunk(
	'publishTestAnswers',
	async ({ answers: answersObj, history, setSubmitting }) => {
		try {
			const answers = Object.entries(answersObj).map(([id, score]) => ({ id, score }));
			const requestBody = { answers };
			const { data: personalities } = await axiosService.instance.post(
				'/personality-test',
				requestBody
			);
			history.push('/personality-test/success', { personalities });
		} catch (error) {
			genericRefreshToast();
			setSubmitting(false);
			throw error;
		}
	}
);

const initialState = { testItems: null };
const personalityTestSlice = createSlice({
	name: 'personalityTest',
	initialState,
	reducers: {
		revertState: () => initialState,
	},
	extraReducers: {
		[fetchTestItems.fulfilled]: (state, action) => {
			const { testItems } = action.payload;
			state.testItems = testItems;
		},
	},
});

export const { revertState } = personalityTestSlice.actions;

export const selectPersonality = ({ personality, history, setSubmitting }) => async (dispatch) => {
	setSubmitting(true);
	try {
		const requestBody = { personality };
		const result = await axiosService.instance.post('/personality-test/select', requestBody);
		const progress = result.data;

		dispatch(updatePersonality({ personality }));

		if (progress) {
			dispatch(setProgress(progress));
		}

		history.push('/');
		dispatch(revertState());
	} catch (error) {
		setSubmitting(false);
		genericRefreshToast();
		throw error;
	}
};

export const getTestItems = (state) => state.personalityTest.testItems;

export default personalityTestSlice.reducer;
