import palette from '../palette';

export default {
	root: {
		boxShadow: 'none',
		borderRadius: '24px',
		padding: '10px 16px',
		border: '2px solid transparent',
		height: '40px',
	},
	// Larged size button overwrites typography rules
	sizeLarge: {
		fontFamily: 'Work Sans',
		fontWeight: 600,
		fontSize: '14px',
		lineHeight: '16px',
		letterSpacing: '0.5px',
		padding: '22px 32px',
	},
	contained: {
		boxShadow: 'none',
		'&:hover': {
			boxShadow: 'none',
		},
		'&.Mui-disabled': {
			backgroundColor: palette.gray[100],
			color: 'white',
		},
	},
	outlinedPrimary: {
		borderColor: palette.primary.main,
		borderWidth: 2,
		'&:hover': {
			borderWidth: 2,
		},
	},
};
