export default {
	colorPrimary: {
		zIndex: 1,
	},
	colorSecondary: {
		zIndex: 0,
		color: '#F5F6F9',
		position: 'absolute',
	},
};
