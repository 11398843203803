import palette from '../palette';

export default {
	root: {
		color: palette.icon,
		'&:hover': {
			backgroundColor: palette.gray[100],
		},
		'&$selected': {
			backgroundColor: palette.gray[50],
			color: palette.primary.main,
			'&:hover': {
				backgroundColor: palette.gray[100],
			},
		},
		'&:first-child': {
			borderTopLeftRadius: 8,
			borderBottomLeftRadius: 8,
		},
		'&:last-child': {
			borderTopRightRadius: 8,
			borderBottomRightRadius: 8,
		},
	},
};
