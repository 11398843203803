export default {
	dashboard: 'Irányítópult',
	news: 'Hírfolyam',
	modules: 'Modulok',
	moduleList: 'Modullista',
	events: 'Események',
	findEntrepreneurs: 'Felhasználók',
	settings: 'Profil beállítások',
	partners: 'Partnerek',
	help: 'Segítség',
};
