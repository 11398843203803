import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './en';
import hu from './hu';

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'hu',
		interpolation: {
			escapeValue: false,
		},
		resources: { hu, en },
	});

export default i18n;
