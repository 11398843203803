import { AppBar, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ProfileHeader from 'app/components/ProfileHeader';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export default function PersonalityTestTopBar({ profile }) {
	const classes = useStyles();

	return (
		<AppBar className={classes.root} color="inherit">
			<Toolbar className={classes.toolbar}>
				<RouterLink to="/">
					<div className={classes.imgContainer}>
						<img alt="Logo" src="./images/logo.svg" />
					</div>
				</RouterLink>
				{profile && <ProfileHeader />}
			</Toolbar>
		</AppBar>
	);
}

PersonalityTestTopBar.propTypes = {
	profile: PropTypes.bool,
};

PersonalityTestTopBar.defaultProps = {
	profile: false,
};

const useStyles = makeStyles((theme) => ({
	root: {
		boxShadow: 'none',
	},
	imgContainer: {
		height: '100%',
		backgroundColor: theme.palette.primary.main,
		margin: '0 -24px',
		padding: '16px 30px',
		width: '200px',
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '140px',
		},
	},
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
	},
}));
