import { Button, ListItem } from '@material-ui/core';
import { ExitToAppOutlined } from '@material-ui/icons';
import { logoutUser } from 'app/store/slices/auth.slice';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useStyles from './ListItem.styles';

const SidebarListItemLogout = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	return (
		<ListItem className={classes.itemLeaf} disableGutters>
			<Button className={classes.buttonLeaf} onClick={() => dispatch(logoutUser())}>
				<ExitToAppOutlined className={classes.icon} />
				{t(`common.logout`)}
			</Button>
		</ListItem>
	);
};

export default SidebarListItemLogout;
