/* eslint-disable no-template-curly-in-string */
export default {
	email: 'Hibás e-mail cím',
	required: 'Kötelező mező',
	min: 'Legalább ${min} karakter legyen',
	max: 'Legfeljebb ${max} karakter lehet',
	minLanguage: 'Legalább ${min} nyelv legyen',
	matchPassword: 'A két jelszó nem egyezik',
	matchIdentityNumberFormat: 'Formátum: AA123456',
	notSameLanguage: 'A két nyelv nem egyezhet',
	positive: 'A megadott szám nem pozitív',
	notBeforeToday: 'Nem lehet korábbi a mai napnál',
	notBeforeRef: 'Nem lehet korábbi az ajánlatadás határidejénél',
	notType: 'Helytelen formátum',
	matches: 'Legalább 1 szám legyen',
	tooLargeFile: 'A fájl túl nagy, a maximum méret: ',
	array: {
		min: 'Legalább ${min} lehetőséget szükséges kiválasztani',
	},
	limit: {
		uploadMax: 'Feltölthető dokumentumok száma : {{count}}',
		uploadMaxError: 'Legfeljebb {{count}} file feltöltésére van lehetőség',
	},
};
