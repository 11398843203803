export default {
	title: 'Partnerek',
	subtitle:
		'Segítséget keresnél, vagy esetleg önkénteskednél más, családokkal foglalkozó civil szervezeteknél? Nézz körül és vedd fel a kapcsolatot partnereinkkel.',
	item: {
		email: 'E-mail cím',
		web: 'Weboldal',
		tel: 'Telefonszám',
	},
};
