export default {
	onboardButtons: {
		prev: 'Vissza',
		next: 'Tovább',
	},
	onboardingTitle: 'Onboarding',
	onboardingSubTitle:
		'Az irányítópult megtekintése előtt olvasd el a félévhez tartozó legfontosabb információkat!',
	onboarded: {
		submit: 'belépés',
	},
	welcome: {
		header: {
			title: 'Üdv,',
			subtitle:
				'A képzés kezdete előtt, kérjük válaszoljon pár kérdésre a vállalkozásával kapcsolatban.',
		},
		subheader: {
			title: 'Milyen innovációs szintet képvisel a vállalkozása?',
			button: 'Teszt Inditás',
			text: 'A felmérés célja, a képzésben résztvevő mikro-,kis-, és középvállalkozások jelenlegi innovációs fejlettségének meghatározása a személyre szabott tanulási élmény érdekében. A teszt  20 kérdésből áll, vezetés, struktúra és folyamatrendszerek, vállalati kultúra és a munkatársak témakörét érinti.',
		},
		levels: {
			title: 'Innovációs szintek',
			button: 'további információ',
			maradi: {
				prefix: 'a',
				title: 'Maradi',
				subtitle:
					'A Maradi kategóriába került vállalkozásnak, a vállalati stratégiájában egyáltalán nem szerepelnek innovációs törekvések. Az újításokban rejlő potenciált érzi, de a megszokott működési folyamatain túl nem szorgalmazza újabbak bevezetését.',
			},
			koveto: {
				prefix: 'a',
				title: 'Követő',
				subtitle:
					'A Követő kategóriába került vállalkozások életében már észlelhető bizonyos innovációs törekvés. A vállalkozás nyitott az innovációra, viszont konkrét célok meghatározásának hiányában újító folyamatok nem tudnak hibátlanul végbemenni.',
			},
			ujito: {
				prefix: 'az',
				title: 'Újító',
				subtitle:
					'Az Újító kategóriába került vállalkozások számára nem ismeretlen az innováció, a vállalati stratégiája fontos részét képezi. A vállalkozás nyitott mindennemű változtatásra, sőt élt is már innovációs módszerekkel.',
			},
		},
	},
	detail: {
		headline: {
			title: 'Mit jelent',
			titleEnd: 'besorolás',
		},
		summary: {
			ceo: {
				title: '-k, mint felfedezők',
			},
			cto: {
				title: '-k, mint analitikusok',
			},
			pm: {
				title: '-ek, mint védelmezők',
			},
			kreativ: {
				title: '-ok, mint diplomaták',
			},
			testButton: 'Teszt indítás',
			backButton: 'Vissza',
		},
	},
	test: {
		header: {
			title: 'Innovációs teszt',
			subtitle: '',
		},
		subheader: {
			title: 'Milyen innovációs szintet képvisel a vállalkozása?',
			subtitle: 'Válaszoljon őszintén a pontos eredményért.',
		},
		form: {
			buttons: {
				next: 'Következő',
				prev: 'Vissza',
				submit: 'Teszt beküldése',
			},
			text: {
				agree: 'Egyetértek',
				disagree: 'Nem értek egyet',
			},
		},
		info: {
			title: 'Javaslatok a kitöltéshez',
			text1: 'Saját vállalkozására vonatkozóan adja meg válaszait.',
			text2: 'Fontos, hogy őszintén értékelje vállalkozását.',
			text3: 'Próbáljon meg nem semleges választ adni.',
		},
	},
	success: {
		headline: {
			title: 'Innovációs teszt eredménye',
			subtitle:
				'A program során az innovációs szintnek megfelelően bónusz tananyagokat fog kapni.',
		},
		summary: {
			titleStart: 'Vállalkozására legjobban jelenleg',
			titleEnd: 'innovációs szint illik.',
			submit: 'Folytatás mint',
			maradi: {
				text: 'Ön praktikus és tényeket kedvelő vezető, aki tiszteli a hagyományokat. Kiváló ügyintéző, aki a jelenben él és elégedett a jelenlegi állapotokkal. Bizonytalan az új dolgokkal szemben, amelynek valószínüleg az ismeretlentől való félelem lehet az egyik oka.',
				long: 'A vállalati kultúráját kockázatkerülés jellemzi, nem jellemző  iteratív ciklusokban való működés. Az elmúlt években nem vezetett be új terméket, nem fejlesztette folyamatait és nem élt egyéb K+F beruházásokkal. Nem érzi úgy, hogy az innováció szignifikánsan segítené a vállalkozása életet. A munkatársai kiválasztásánál nem feltétlenül törekszik sokféle készséggel, innovációs szemléletmóddal rendelkező alkalmazott megtalálására, valamint nem serkenti az innovációs kompetenciák fejlesztését. A KKV innovációs platform hasznos segítség lehet a szemléletváltásban és az új lehetőségek kiakanázására. "Az innováció két már létező dolgot vesz át, és új módon állítja össze őket. - Tom Freston"',
			},
			koveto: {
				text: 'Ön egy bátor, törekvő vezető, akinek fontos a vállalkozása, a munkatársai. Nyitott az innovációra, gyakran keresi és követi az új trendeket, azonban bizonyos stratégia szempontok hiányában, az újítási folyamatok nem mindig mennek végbe. Döntéseit jól átgondolja, a kockázatot csekély mértékben vállal.',
				long: 'Ennek oka lehet, hogy  stratégia szinten nem jelent még meg a cég életében. A munkatársak kiválasztásánál megvan a törekvés az újszerű, innovatív szemlélettel rendelkező kolléga alkalmazására, de nehéz megtalálni a megfelelő személyt a megfelelő körülmények hiányában. Folyamatosan keresi és nyomon követi az innovációs lehetőségeket és fel is keresett már erre külső partnereket és erőforrásokat, azonban az innovációs lehetőségek azonosítására és kihasználásra nem hozott létre még hivatalos struktúrát. Az KKV innovációs platform hasznos segítség lesz a meglévő törekvések cselekvéssé alakításához és az eddig hiányos ismeretek pótlására. "A jól megfogalmazott probléma félig megoldott probléma. - Charles Kettering"',
			},
			ujito: {
				text: 'Ön egy igazán innovatív, nyitott és bátor vezető, aki képes holisztikus szemléletben irányítani a vállalkozását és munkatársait. Nem riad vissza az újdonságoktól, legyen szó egy új termék vagy szolgáltatás bevezetéséről, vagy digitalizációs folyamatokról. Mer stratégia döntéseket hozni és ezzel kockázatot vállalni, annak érdekében, hogy a vállalkozása tovább fejlődjön illetve ügyfeleinek életét pozitívan befolyásolja.',
				long: 'Pontosan tudja és érti az újításokban rejlő potenciált a vállalkozása életében. Nem fél kockázatot vállalni, annak érdekében, hogy ügyfelei életét pozitív irányba befolyásolja valamint vállalkozása teljesítményét növelni tudja. A céget fejlett belső együttműködési rendszer jellemzi, több funkcionális terület dolgozik együtt a problémák megoldásán. A vezetők támogatják az újító kezdeményezéseket, nyilvánosan elismerek a csapatok/ egyének innovációs teljesítményét. A Vállalkozás olyan munkatársak alkalmazására törekszik, akik sokféle  készséggel, innovációs szemlélettel rendelkeznek, továbbá olyan programok elérését teszi lehetővé, amelyek az alkalmazottak innovációs kompetenciáját fejleszteni tudja. "Az innováció azért történik, mert vannak emberek, akik nagyon sok mindent csinálnak és próbálnak." - Edward Felten',
			},
		},
		button: {
			title: 'kiválaszt',
			selected: 'kiválasztva',
		},
		notSure: {
			title: 'Nem érzed megfelelőnek az eredményt?',
			subtitle: 'Válaszd ki te magad, melyik személyiséget érzed magadénak',
		},
	},
};
