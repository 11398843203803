/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import { Button, ListItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink } from 'react-router-dom';
import useStyles from './ListItem.styles';

const CustomRouterLink = forwardRef((props, ref) => (
	<div ref={ref} style={{ flexGrow: 1 }}>
		<RouterLink {...props} />
	</div>
));

// style can be a paddingLeft in case of nested items
const SimpleListItem = ({ item, onNavigation, itemStyle }) => {
	const { titleKey, href, icon: Icon } = item;
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<ListItem className={classes.itemLeaf} disableGutters style={itemStyle}>
			<Button
				activeClassName={classes.active}
				className={classes.buttonLeaf}
				component={CustomRouterLink}
				onClick={() => onNavigation()}
				exact
				to={href}
			>
				{Icon && <Icon className={classes.icon} />}
				{t(`navigation.${titleKey}`)}
			</Button>
		</ListItem>
	);
};

SimpleListItem.defaultProps = {
	itemStyle: {},
};

SimpleListItem.propTypes = {
	item: PropTypes.shape({
		href: PropTypes.string.isRequired,
		icon: PropTypes.object,
		titleKey: PropTypes.string.isRequired,
	}).isRequired,
	onNavigation: PropTypes.func.isRequired,
	itemStyle: PropTypes.object,
};

export default SimpleListItem;
