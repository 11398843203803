import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from 'app/store/axiosService';
import { genericRefreshToast } from '../toast';

export const fetchUniversityScores = createAsyncThunk(
	'statistics/fetchUniversityScores',
	async () => {
		try {
			const response = await axiosService.instance.get(`/statistics/university`);
			return response.data;
		} catch (error) {
			genericRefreshToast();
			throw error;
		}
	}
);

export const fetchStudentScores = createAsyncThunk('statistics/fetchStudentScores', async () => {
	try {
		const response = await axiosService.instance.get(`/statistics/student`);
		return response.data;
	} catch (error) {
		genericRefreshToast();
		throw error;
	}
});

export const fetchStatisticsForUniversity = createAsyncThunk(
	'statistics/fetchStatisticsForUniversity',
	async () => {
		const requestPath = `/dashboard/university-user`;
		const response = await axiosService.instance.get(requestPath);
		return response.data;
	}
);

export const fetchStatisticsForOffice = createAsyncThunk(
	'statistics/fetchStatisticsForOffice',
	async (params) => {
		const { university } = params;
		const requestPath = `/dashboard/office-user/university/${university}`;
		const response = await axiosService.instance.get(requestPath);
		return response.data;
	}
);

export const statisticsSlice = createSlice({
	name: 'statistics',
	initialState: {
		universityScores: null,
		studentScores: null,
		statistics: {
			isLoaded: false,
			progress: null,
			personality: null,
			registration: null,
			course: null,
		},
	},
	extraReducers: {
		[fetchUniversityScores.fulfilled]: (state, action) => {
			state.universityScores = action.payload;
		},
		[fetchStudentScores.fulfilled]: (state, action) => {
			state.studentScores = action.payload;
		},
		[fetchStatisticsForUniversity.pending]: (state) => {
			state.statistics.isLoaded = false;
		},
		[fetchStatisticsForUniversity.fulfilled]: (state, action) => {
			state.statistics = action.payload;
			state.statistics.isLoaded = true;
		},
		[fetchStatisticsForOffice.pending]: (state) => {
			state.statistics.isLoaded = false;
		},
		[fetchStatisticsForOffice.fulfilled]: (state, action) => {
			state.statistics = action.payload;
			state.statistics.isLoaded = true;
		},
	},
});

export const selectUniversityScores = ({ statistics: { universityScores } }) => ({
	data: universityScores,
	isDataAvailable: universityScores !== null,
});

export const selectStudentScores = ({ statistics: { studentScores } }) => ({
	data: studentScores,
	isDataAvailable: studentScores !== null,
});

export const selectProgressStatistics = (state) => selectStatisticItem(state, 'progress');
export const selectPersonalityStatistics = (state) => selectStatisticItem(state, 'personality');
export const selectRegistrationStatistics = (state) => selectStatisticItem(state, 'registration');
export const selectCourseStatistics = (state) => selectStatisticItem(state, 'course');

function selectStatisticItem(state, item) {
	return {
		data: state.statistics.statistics[item],
		isLoaded: state.statistics.statistics.isLoaded,
	};
}

export default statisticsSlice.reducer;
