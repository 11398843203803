export default {
	header: {
		title: 'Felhasznáklók',
		subtitle:
			'Tekintsd át a felhasználók listáját, keress személyekre vagy szűrj az oszlopok szerint.',
	},
	noResult: {
		text1: 'A megadott feltételekkel nem találtunk vállalkozót.',
		text2: 'Kérjük bővítsd a szűrési feltételeket.',
	},
	tableHeaders: {
		name: 'Név',
		email: 'E-mail-cím',
		companyName: 'Vállalat',
		points: 'Pont',
		percent: '%',
	},
};
