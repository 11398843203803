import { LinearProgress, makeStyles } from '@material-ui/core';
import GuestTopbar from 'app/components/GuestTopBar';
import { LAYOUT_TYPES } from 'app/constants';
import { useRouterGuard } from 'app/guards/useRouterGuard';
import { loaderSelector } from 'app/store/slices/loader.slice';
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';

const headerHeight = 56;
const mobileHeaderHeight = 64;

const useStyles = makeStyles((theme) => ({
	content: {
		paddingTop: headerHeight,
		[theme.breakpoints.up('sm')]: {
			paddingTop: mobileHeaderHeight,
		},
		height: '100vh',
		backgroundColor: theme.palette.background.default,
	},
}));

export default function OnboardingLayout({ route }) {
	const classes = useStyles();
	const { isLoading } = useSelector(loaderSelector);

	useRouterGuard(LAYOUT_TYPES.ONBOARDING);

	return (
		<>
			<GuestTopbar />
			<main className={classes.content}>
				<Suspense fallback={<LinearProgress />}>
					{isLoading && <LinearProgress />}
					{renderRoutes(route.routes)}
				</Suspense>
			</main>
		</>
	);
}
