export default {
	buttons: {
		prev: 'Vissza',
		next: 'Tovább',
	},
	title: 'Onboarding',
	onboarded: {
		title: 'Onboard',
		submit: 'Befejezés',
	},
};
