import { createAsyncThunk } from '@reduxjs/toolkit';
import i18n from 'app/i18n';
import axiosService from 'app/store/axiosService';
import { getEvents } from 'app/store/slices/event/events.slice';
import type { Event } from 'features/events/type/Event.type';
import { genericRefreshToast, successToast } from '../../toast';

const baseUrl = '/event';

// eslint-disable-next-line no-unused-vars
type LoadingSetter = (value: boolean) => void;

type CreateEventParams = {
	event: Event;
	id: string;
	setIsLoading: LoadingSetter;
	handleClose: () => void;
};

export const createEvent = createAsyncThunk(
	'events/create',
	async (params: CreateEventParams, thunkApi) => {
		const { dispatch } = thunkApi;
		const { event, handleClose, setIsLoading } = params;

		try {
			const response = await axiosService.instance.post(baseUrl, event);

			// Referesh pagination's state
			dispatch(getEvents({}));

			setIsLoading(false);
			successToast(i18n.t('events.upload.toastSuccessMsg'));
			handleClose();

			return response.data.event as Event; // TODO backend
		} catch (error) {
			setIsLoading(false);
			genericRefreshToast();

			throw error;
		}
	}
);

type UpdateEventParams = {
	event: Event;
	id: string;
	setIsLoading: LoadingSetter;
	handleClose: () => void;
};

export const updateEvent = createAsyncThunk('events/update', async (params: UpdateEventParams) => {
	const { event, id, handleClose, setIsLoading } = params;

	try {
		const response = await axiosService.instance.put(`${baseUrl}/${id}`, event);
		successToast(i18n.t('events.edit.toastSuccessMsg'));
		handleClose();
		return response.data.event as Event; // TODO backend
	} catch (error) {
		genericRefreshToast();
		throw error;
	} finally {
		setIsLoading(false);
	}
});

type DeleteEventParams = {
	eventId: string;
	setLoading: LoadingSetter;
};
export const deleteEvent = createAsyncThunk(
	'events/delete',
	async (params: DeleteEventParams, thunkApi) => {
		const { dispatch } = thunkApi;
		const { eventId, setLoading } = params;

		try {
			await axiosService.instance.delete(`${baseUrl}/${eventId}`);

			// Referesh pagination's state
			dispatch(getEvents({}));

			successToast(i18n.t('events.delete.toastSuccessMsg'));
			return { id: eventId };
		} catch (error) {
			genericRefreshToast();
			throw error;
		} finally {
			setLoading(false);
		}
	}
);
