/* eslint-disable react/forbid-prop-types */
import { Container, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LAYOUT_TYPES } from 'app/constants';
import { useRouterGuard } from 'app/guards/useRouterGuard';
import { loaderSelector } from 'app/store/slices/loader.slice';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import PersonalityTestTopBar from './PersonalityTestTopBar';

export default function PersonalityTestLayout({ route }) {
	const classes = useStyles();
	const { isLoading } = useSelector(loaderSelector);

	useRouterGuard(LAYOUT_TYPES.PERSONALITY);

	return (
		<Suspense fallback={<LinearProgress />}>
			{isLoading && <LinearProgress />}
			<Container>
				<PersonalityTestTopBar profile />
				<main className={classes.content}>{renderRoutes(route.routes)}</main>
			</Container>
		</Suspense>
	);
}

PersonalityTestLayout.propTypes = {
	route: PropTypes.shape({
		path: PropTypes.string,
		component: PropTypes.func,
		routes: PropTypes.array,
	}).isRequired,
};

const headerHeight = 56;
const mobileHeaderHeight = 64;
const topGutter = 30;

const useStyles = makeStyles((theme) => ({
	content: {
		marginTop: topGutter,
		paddingTop: headerHeight,
		[theme.breakpoints.up('sm')]: {
			paddingTop: mobileHeaderHeight,
		},
	},
}));
