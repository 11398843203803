/* eslint-disable react/forbid-prop-types */
import { Box, Button, ListItem } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './ListItem.styles';
import SidebarListItem from './SimpleListItem';

function useChevron(initialIsOpen = false) {
	const [isOpen, setIsOpen] = useState(initialIsOpen);

	const toggleState = () => {
		setIsOpen((currState) => !currState);
	};

	return [isOpen, toggleState];
}

const NestedListItem = ({ item, onNavigation }) => {
	const { titleKey, icon: Icon, menuItems } = item;
	const classes = useStyles();
	const { t } = useTranslation();
	const [isOpen, toggleState] = useChevron(true);

	const ChevronIcon = isOpen ? KeyboardArrowDownIcon : KeyboardArrowUpIcon;

	const renderNestedItems = () => {
		return menuItems.map((menuItem) => (
			<SidebarListItem
				key={menuItem.titleKey}
				item={menuItem}
				itemStyle={{ paddingLeft: '20%' }}
				onNavigation={onNavigation}
			/>
		));
	};

	return (
		<ListItem className={classes.itemLeaf} disableGutters style={{ display: 'block' }}>
			<Button
				activeClassName={classes.active}
				className={classes.buttonLeaf}
				onClick={toggleState}
			>
				{Icon && <Icon className={classes.icon} />}
				{t(`navigation.${titleKey}`)}
				<Box marginLeft="auto">
					<ChevronIcon />
				</Box>
			</Button>
			{isOpen && renderNestedItems()}
		</ListItem>
	);
};

NestedListItem.propTypes = {
	item: PropTypes.shape({
		icon: PropTypes.object.isRequired,
		titleKey: PropTypes.string.isRequired,
		menuItems: PropTypes.arrayOf(
			PropTypes.shape({
				titleKey: PropTypes.string.isRequired,
				href: PropTypes.string.isRequired,
			}).isRequired
		),
	}).isRequired,
	onNavigation: PropTypes.func.isRequired,
};

export default NestedListItem;
