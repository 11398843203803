import { Hidden, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LAYOUT_TYPES } from 'app/constants';
import { useRouterGuard } from 'app/guards/useRouterGuard';
import { loaderSelector } from 'app/store/slices/loader.slice';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import GuestTopBar from '../../components/GuestTopBar';

const headerHeight = 56;
const mobileHeaderHeight = 64;

const useStyles = makeStyles((theme) => ({
	content: {
		paddingTop: headerHeight,
		[theme.breakpoints.up('sm')]: {
			paddingTop: mobileHeaderHeight,
		},
		minHeight: `calc(100vh - ${mobileHeaderHeight}px)`,
		backgroundColor: theme.palette.background.default,
	},
	backgroundContainer: {
		position: 'relative',
	},
	pattern1: {
		position: 'fixed',
		bottom: 0,
		right: 0,
	},
	pattern2: {
		position: 'fixed',
		top: headerHeight + 8,
		left: 0,
	},
}));

const AuthLayout = ({ route }) => {
	const classes = useStyles();
	const { isLoading } = useSelector(loaderSelector);

	useRouterGuard(LAYOUT_TYPES.GUEST);

	return (
		<>
			<GuestTopBar />
			<main className={classes.content}>
				<Hidden smDown>
					<img src="./images/pattern-02.svg" alt="alt" className={classes.pattern2} />
					<img src="./images/pattern-01.svg" alt="alt" className={classes.pattern1} />
				</Hidden>
				<Suspense fallback={<LinearProgress />}>
					{isLoading && <LinearProgress />}
					{renderRoutes(route.routes)}
				</Suspense>
			</main>
		</>
	);
};

AuthLayout.propTypes = {
	route: PropTypes.shape({
		path: PropTypes.string,
		component: PropTypes.func,
		routes: PropTypes.array,
	}).isRequired,
};

export default AuthLayout;
