export default {
	buttons: {
		cancel: 'Mégsem',
		ok: 'Rendben',
		next: 'Következő',
		previous: 'Előző',
		back: 'Vissza',
		forward: 'Tovább',
		close: 'Bezárás',
		finish: 'Véglegesítés',
	},
	logout: 'Kijelentkezés',
	contact: {
		email: 'E-mail cím',
		mobile: 'telefonszám',
	},
	formats: {
		image: '.png, .jpg',
		pdf: '.pdf',
		spreadsheet: '.xls, .xlsx, .csv',
		document: '.doc, .docx',
	},
	filter: {
		title: 'Szűrés',
		searchPlaceholder: 'Keresés...',
		clearSelection: 'Összes feltétel törlése',
		result: 'találat',
		groups: {
			universities: 'Egyetemek',
			focuses: 'Piaci Fókusz',
			specializations: 'Szakterületek',
			categories: 'Kategóriák',
		},
		showLess: 'Kevesebb feltétel',
		showMore: 'Több feltétel',
	},
	maxMb: 'Maximum 5 MB',
};
