import {
	BookOutlined,
	DashboardOutlined,
	FormatListBulleted,
	InfoOutlined,
	PeopleOutlined,
	SettingsOutlined,
	StarBorderOutlined,
	SupervisedUserCircleOutlined,
} from '@material-ui/icons';
import { URL_PREFIXES } from 'app/constants';

const { STUDENT, OFFICE } = URL_PREFIXES;

export const navbarItems = {
	student: [
		{
			titleKey: 'dashboard',
			href: `${STUDENT}/`,
			icon: DashboardOutlined,
		},
		{
			titleKey: 'modules',
			href: `${STUDENT}/modules`,
			icon: BookOutlined,
		},
		{
			titleKey: 'news',
			href: `${STUDENT}/news`,
			icon: FormatListBulleted,
		},
		{
			titleKey: 'events',
			href: `${STUDENT}/events`,
			icon: StarBorderOutlined,
		},
		{
			titleKey: 'settings',
			href: `${STUDENT}/settings`,
			icon: SettingsOutlined,
		},
		{
			titleKey: 'partners',
			href: `${STUDENT}/partners`,
			icon: SupervisedUserCircleOutlined,
		},
		{
			titleKey: 'help',
			href: `${STUDENT}/help`,
			icon: InfoOutlined,
		},
	],
	office: [
		{
			titleKey: 'dashboard',
			href: `${OFFICE}/`,
			icon: DashboardOutlined,
		},
		{
			titleKey: 'modules',
			href: `${OFFICE}/modules`,
			icon: BookOutlined,
		},
		{
			titleKey: 'events',
			href: `${OFFICE}/events`,
			icon: StarBorderOutlined,
		},
		{
			titleKey: 'findEntrepreneurs',
			href: `${OFFICE}/find-entrepreneurs`,
			icon: PeopleOutlined,
		},
		{
			titleKey: 'news',
			href: `${OFFICE}/news`,
			icon: FormatListBulleted,
		},
		{
			titleKey: 'settings',
			href: `${OFFICE}/settings`,
			icon: SettingsOutlined,
		},
		{
			titleKey: 'partners',
			href: `${OFFICE}/partners`,
			icon: SupervisedUserCircleOutlined,
		},
		{
			titleKey: 'help',
			href: `${OFFICE}/help`,
			icon: InfoOutlined,
		},
	],
};
