import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from 'app/store/axiosService';
import { RootState } from 'app/store/store';
import { genericRefreshToast } from 'app/store/toast';
import type { Event } from 'features/events/type/Event.type';

const baseUrl = '/event';

type EventDetailsState = {
	event: Event | null;
};

const initialState: EventDetailsState = {
	event: null,
};

type FindEventDetailsParams = {
	eventId: string;
};

export const findEventDetails = createAsyncThunk(
	'events/find-details',
	async ({ eventId }: FindEventDetailsParams) => {
		try {
			const response = await axiosService.instance.get(`${baseUrl}/${eventId}`);
			const event: Event = response.data;

			return { event };
		} catch (error) {
			genericRefreshToast();
			throw error;
		}
	}
);

export const eventDetailsSlice = createSlice({
	name: 'eventDetails',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(findEventDetails.pending, (state) => {
			state.event = null;
		});
		builder.addCase(findEventDetails.fulfilled, (state, action) => {
			const { event } = action.payload;
			state.event = event;
		});
	},
});

export default eventDetailsSlice.reducer;

export const selectEventDetails = (state: RootState) => state.eventDetail.event;
