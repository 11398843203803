import { URL_PREFIXES } from 'app/constants';
import { OfficeLayout } from 'app/layouts/User';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const { OFFICE } = URL_PREFIXES;

const officeRoutes = {
	path: OFFICE,
	component: OfficeLayout,
	routes: [
		{
			path: `${OFFICE}`,
			exact: true,
			component: lazy(() => import('features/dashboard/office')),
		},
		{
			path: `${OFFICE}/news`,
			exact: true,
			component: lazy(() => import('features/news')),
		},
		{
			path: `${OFFICE}/find-entrepreneurs`,
			exact: true,
			component: lazy(() => import('features/find-entrepreneurs')),
		},
		{
			path: `${OFFICE}/help`,
			exact: true,
			component: lazy(() => import('features/help')),
		},

		{
			path: `${OFFICE}/modules`,
			exact: true,
			component: lazy(() => import('features/progress/progress')),
		},
		{
			path: `${OFFICE}/events`,
			exact: true,
			component: lazy(() => import('features/events')),
		},
		{
			path: `${OFFICE}/events/:eventId`,
			exact: true,
			component: lazy(() => import('features/event-details')),
		},
		{
			path: `${OFFICE}/lessons/:id`,
			exact: true,
			component: lazy(() => import('features/lesson/lesson')),
		},
		{
			path: `${OFFICE}/settings`,
			exact: true,
			component: lazy(() => import('features/settings')),
		},
		{
			path: `${OFFICE}/partners`,
			exact: true,
			component: lazy(() => import('features/partners')),
		},
		{
			component: () => <Redirect to="/errors/error-404" />,
		},
	],
};

export default officeRoutes;
