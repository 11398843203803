import { colors } from '@material-ui/core';
import palette from '../palette';

export default {
	root: {
		backgroundColor: palette.lightGrey,
		color: palette.secondary[700],
	},
	deletable: {
		'&:focus': {
			backgroundColor: colors.blueGrey[100],
		},
	},
};
