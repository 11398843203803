import { makeStyles } from '@material-ui/core/styles';
import palette from 'app/theme/palette';

const useStyles = makeStyles(() => ({
	root: {
		background: 'transparent',
		height: '100%',
		overflowY: 'auto',
		width: 220,
		flex: '0 0 auto',
	},
	content: {
		position: 'fixed',
		minWidth: '14em',
	},
	logoContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		minHeight: 'fit-content',
		backgroundColor: palette.blue.main,
	},
	logoLink: {
		textAlign: 'center',
		width: '100%',
		height: 80,
	},
	navigation: {
		paddingTop: 54,
		background: '#fff',
		height: 'calc(100vh - 134px)', // 134 = 80 height of logo + 54 paddingTop
		position: 'relative',
	},
	sidebarList: {
		width: '100%',
	},
	logo: {
		width: 103,
		height: 80,
	},
}));

export default useStyles;
