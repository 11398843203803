export default {
	primary: {
		semester: 'I. félév',
		topics: {
			1: {
				title: 'Üdvözlünk az Evita digitális családtámogató programjában',
				alt: 'evita',
				text: 'Kérjük, hogy a következő rövid bevezetőt figyelmesen olvasd el, hogy megismerd a program legfontosabb tudnivalóit! Ezeket a továbbiakban majd a “Segítség” menüpont alatt találhatod.',
			},
			2: {
				title: 'A tananyag',
				alt: 'evita',
				text: 'Az Evita tananyaga 5 modulból áll, amikben megismerkedhetsz a családalapítás teljes folyamatával. A tananyagok a nőgyógyászat, gyermekgyógyászat, mentálhigiéné, gyógypedagógia és gyógytornászat témaköreiből kerültek ki. A szakembereink által megírt segédletek segítségedre lehetnek a fogantatástól kezdve, egészen gyermeked iskolaérettségéig.',
			},
			3: {
				title: 'Tesztek',
				alt: 'evita',
				text: 'Az Evita modulokat lezáró tesztjeitől nem kell megijedned. A rövid kérdéssor abban segít neked, hogy megtudd, mennyire sikerült elsajátítanod az információkat. Ne búsulj, ha a teszt nem sikerül, hiszen nincs számodra semmilyen negatív következménye!',
			},
			4: {
				title: 'Irányítópult',
				alt: 'evita',
				text: 'Bejelentkezés után az “Irányítópult” oldalon találod majd magadat. Itt az eddig elért eredményeidet és a kurzusban való előrehaladásodat láthatod. Kövesd az eredményeket hétről-hétre és folytasd a tananyagokat a saját tempódban!',
			},
			5: {
				title: 'Események menüpont',
				alt: 'evita',
				text: 'Nézd meg mi történik lakóhelyedhez közel vagy akár online! Szeretnél konzultációkon, egészségnapokon, ingyenes felkészítőkön vagy szűrésen részt venni? Partnereink folyamatosan meghirdetnek majd alkalmakat, amelyeken érdemes részt venni!',
			},
			6: {
				title: 'Partnerek',
				alt: 'evita',
				text: 'Az Evita nemcsak hiteles gyakorlati segédletet biztosít a családalapítás során, de megbízható partnereket is, akik segítségedre lehetnek jóban-rosszban. Amennyiben a családalapítás során problémákkal néznél szembe, böngészd végig partnereinket a “Partner” fülre kattintva és találj segítséget azonnal!',
			},
			7: {
				title: 'Hírfolyam',
				alt: 'evita',
				text: 'Az eseményeken túl az oldal legfrissebb hírei, bejelentései a hírfolyamon kerülnek meghirdetésre majd. Ne felejtsd el rendszeresen megnézni, mert sok érdekes tartalmat találhatsz majd itt!',
			},
			8: {
				title: 'Kérdésed lenne?',
				alt: 'evita',
				text: 'Az oldal használata során előfordulhat, hogy valamilyen funkcióval vagy tananyaggal kapcsolatban észrevételed van. Ha beragad egy gomb, nem nyílik meg az oldal vagy egyéb, a használattal kapcsolatos problémát észlelsz ne habozz, keresd az Evita ügyfélszolgálatát munkaidőben a titkarsag@mik.ma e-mail címen. Ránk számíthatsz!',
			},
		},
	},
};
