const white = '#FFFFFF';

export default {
	primary: {
		contrastText: white,
		main: '#4889AD',
		dark: '#244557', // 700
		light: '#CFE7FE',
		100: '#DAE7EF',
		400: '#6DA1BD',
		600: '#366782',
	},
	secondary: {
		contrastText: white,
		dark: '#69546A',
		main: '#937195',
		light: '#EDDCEE',
		200: ' #EDDCEE',
		400: '#DAB9DD',
		500: '#D1A8D4',
		600: '#937195',
		700: '#69546A',
		800: '#342A35',
	},
	error: {
		contrastText: white,
		dark: '#95040C',
		main: '#E74C3C',
	},
	text: {
		paragraph: '#656565',
		caption: '#B5B5B5',
		button: 'black',
		link: '#80BC00',
		secondary: '#B5B5B5',
	},
	background: {
		default: '#F5FAFD',
		paper: white,
		paperDark: '#ECEFF1',
	},
	innovation: {
		main: {
			maradi: '#E6F2CC',
			koveto: '#CCE499',
			ujito: '#99C933',
		},
		secondary: {
			maradi: '#80BC00',
			koveto: '#608D00',
			ujito: '#FFFFFF',
		},
	},
	divider: 'rgba(151,151,151,0.1)',
	gray: {
		100: '#E1E1E1',
		300: '#B5B5B5',
		500: '#8B8B8B',
		700: '#656565',
		900: '#393939',
	},
	appricot: {
		main: '#FFD3D4',
	},
	green: {
		main: '#A4DBDE',
		50: '#F6FCFC',
		200: '#DBF1F2',
	},
	blue: {
		main: '#86C4FD',
	},
	lightGrey: '#F5FAFD',
};
