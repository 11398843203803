import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from 'app/store/axiosService';
import { getUser } from 'app/store/slices/auth.slice';
import { genericRefreshToast } from '../toast';

const initialState = {
	isPending: false,
	entrepreneurs: null,
	pagination: {
		totalCount: null,
		pageCount: null,
		pageNumber: 1,
	},
	filters: {
		search: '',
	},
};

export const findEntrepreneurs = createAsyncThunk(
	'find-entrepreneur/all',
	async (params = {}, thunkApi) => {
		const { getState, dispatch } = thunkApi;
		const { pageNumber } = params;

		const state = getState();
		const findEntrepreneurState = state.findEntrepreneur;
		const filters = params.filters ?? findEntrepreneurState.filters;

		// prevent multiple search requests
		if (findEntrepreneurState.isPending) {
			return null;
		}

		// if action triggered by filter update, save it in store
		// on page change, filters are sent from store
		if (params.filters) {
			dispatch(updateCachedFilters(params.filters));
		}

		// block further searches until action finishes
		dispatch(startPending());

		try {
			const search = filters?.search ?? null;
			const user = getUser(state);
			const role = user.role.toLowerCase();

			const requestBody = {
				search,
				pageNumber: pageNumber ?? findEntrepreneurState.pagination.pageNumber,
			};
			const response = await axiosService.instance.post(
				`/find-entrepreneur/all/${role}`,
				requestBody
			);
			const { entrepreneurs, pagination } = response.data;

			return { entrepreneurs, pagination };
		} catch (error) {
			genericRefreshToast();
			throw error;
		}
	}
);

export const findEntrepreneurSlice = createSlice({
	name: 'findEntrepreneur',
	initialState,
	reducers: {
		startPending(state) {
			state.isPending = true;
		},
		updateCachedFilters(state, action) {
			state.filters = action.payload;
		},
	},

	extraReducers: {
		[findEntrepreneurs.fulfilled]: (state, action) => {
			// cancelled request
			if (action.payload === null) {
				return;
			}

			state.isPending = false;

			const { entrepreneurs, pagination } = action.payload;
			state.pagination = pagination;
			state.entrepreneurs = entrepreneurs;
		},
	},
});

export const { startPending, updateCachedFilters } = findEntrepreneurSlice.actions;

export const selectFindEntrepreneurList = (state) => state.findEntrepreneur.entrepreneurs;
export const selectFindEntrepreneursPaginationInfo = (state) => state.findEntrepreneur.pagination;
export const selectFindEntrepreneursResultSize = (state) =>
	state.findEntrepreneur.pagination.totalCount ?? null;
export const selectFindEntrepreneurIsPending = (state) => state.findEntrepreneur.isPending;

export default findEntrepreneurSlice.reducer;
