import { Box, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import { logoutUser } from 'app/store/slices/auth.slice';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	box: {
		cursor: 'pointer',
	},
	text: {
		marginBottom: '0px',
		color: theme.palette.primary.main,
		'&:hover': {
			cursor: 'pointer',
		},
	},
	logout: {
		verticalAlign: 'top',
		marginLeft: 8,
		color: theme.palette.primary.main,
		width: '0.6em',
		height: '0.6em',
		[theme.breakpoints.down('xs')]: { width: '1em', height: '1em' },
	},
}));

const ProfileHeader = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	return (
		<Box display="flex" alignItems="center" flexDirection="row">
			<Hidden mdDown>
				<Box
					display="flex"
					alignItems="center"
					className={classes.box}
					onClick={() => dispatch(logoutUser())}
				>
					<Typography className={classes.text} color="secondary" align="right">
						{t('common.logout')}
					</Typography>
					<ExitToAppOutlinedIcon className={classes.logout} fontSize="large" />
				</Box>
			</Hidden>
		</Box>
	);
};

export default ProfileHeader;
