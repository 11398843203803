import palette from '../palette';
import typography from '../typography';

export default {
	root: {
		...typography.caption,
		display: 'table-head',
		backgroundColor: palette.background.default,
	},
};
