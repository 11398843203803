/* eslint-disable no-template-curly-in-string */
import auth from 'features/auth/auth.i18n-hu';
import dashboard from 'features/dashboard/dashboard.i18n-hu';
import events from 'features/events/events.i18n-hu';
import findEntrepreneur from 'features/find-entrepreneurs/find-entrepreneur.i18n-hu';
import help from 'features/help/help.i18n-hu';
import lesson from 'features/lesson/lesson.i18n-hu';
import moduleTest from 'features/module-test/module-test.i18n-hu';
import news from 'features/news/news.i18n-hu';
import onboarding from 'features/onboarding/onboarding.i18n';
import partners from 'features/partners/partners.i18n-hu';
import personalityTest from 'features/personality-test/personality-test.i18n-hu';
import progress from 'features/progress/progress.i18n-hu';
import settings from 'features/settings/settings.i18n-hu';
import formFields from '../../components/form-fields/form-fields.i18n-hu';
import common from './common.i18n-hu';
import error from './error.i18n-hu';
import HSUPTopics from './hsup-topics.i18n-hu';
import navigation from './navigation.i18n-hu';
import validation from './validation.i18n-hu';

export default {
	translation: {
		navigation,
		common,
		validation,
		error,
		dashboard,
		auth,
		personalityTest,
		moduleTest,
		lesson,
		progress,
		settings,
		findEntrepreneur,
		news,
		HSUPTopics,
		onboarding,
		formFields,
		help,
		events,
		partners,
	},
};
