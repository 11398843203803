import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GuestTopbar from 'app/components/GuestTopBar';
import { loaderSelector } from 'app/store/slices/loader.slice';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';

const headerHeight = 56;
const mobileHeaderHeight = 64;

const useStyles = makeStyles((theme) => ({
	content: {
		paddingTop: headerHeight,
		[theme.breakpoints.up('sm')]: {
			paddingTop: mobileHeaderHeight,
		},
		height: '100vh',
		backgroundColor: theme.palette.background.default,
	},
}));

const ErrorLayout = ({ route }) => {
	const classes = useStyles();
	const { isLoading } = useSelector(loaderSelector);

	return (
		<>
			<GuestTopbar />
			<main className={classes.content}>
				<Suspense fallback={<LinearProgress />}>
					{isLoading && <LinearProgress />}
					{renderRoutes(route.routes)}
				</Suspense>
			</main>
		</>
	);
};

ErrorLayout.propTypes = {
	route: PropTypes.shape({
		path: PropTypes.string,
		component: PropTypes.func,
		routes: PropTypes.array,
	}).isRequired,
};

export default ErrorLayout;
