export default {
	header: {
		title: 'Modulok',
		subtitle:
			'Az Evita oldalán 5 modulon keresztül tanulhatsz a családalapítás különböző lépéseiről és a legfontosabb tudnivalókról. Olvass, kérdezz, válaszolj és fejlesszük együtt a tananyagot!',
	},
	testAttempt: {
		start: 'még ',
		end: 'próbálkozásod van',
		zero: 'nincs több próbákozási lehetőséged',
	},
	bonus: 'bónusz',
	moduleListTitle: 'Induljon el az úton',
	moduleListSubtitle:
		'Olvasd el alaposan a modulokhoz tartozó tananyagokat és ha úgy érzed kellően felkészültél töltsd ki a hozzájuk tartozó tesztet! Sok szerencsét!',
	previousSemester: 'Korábbi félév',
};
