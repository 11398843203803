import { URL_PREFIXES } from 'app/constants';
import { PersonalityTestLayout } from 'app/layouts';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const personalityTestRoutes = {
	path: URL_PREFIXES.PERSONALITY,
	component: PersonalityTestLayout,
	routes: [
		{
			path: `${URL_PREFIXES.PERSONALITY}/welcome`,
			exact: true,
			component: lazy(() => import('features/personality-test/welcome')),
		},
		{
			path: `${URL_PREFIXES.PERSONALITY}/detail/:personality`,
			exact: true,
			component: lazy(() => import('features/personality-test/detail')),
		},
		{
			path: `${URL_PREFIXES.PERSONALITY}/`,
			exact: true,
			component: lazy(() => import('features/personality-test/test')),
		},
		{
			path: `${URL_PREFIXES.PERSONALITY}/success`,
			exact: true,
			component: lazy(() => import('features/personality-test/success')),
		},
		{
			component: () => <Redirect to="/errors/error-404" />,
		},
	],
};

export default personalityTestRoutes;
