import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MODULE_PROGRESS_STATUS } from 'app/constants';
import axiosService from 'app/store/axiosService';
import { genericRefreshToast, notificationToast } from '../toast';

const { COMPLETED } = MODULE_PROGRESS_STATUS;

export const fetchProgress = createAsyncThunk('progress/fetchProgress', async () => {
	try {
		const response = await axiosService.instance.get(`/progress`);
		return response.data;
	} catch (error) {
		genericRefreshToast();
		throw error;
	}
});

export const progressSlice = createSlice({
	name: 'progress',
	initialState: {
		currentProgress: {},
		modules: {},
		lessons: {},
	},
	reducers: {
		setProgress: (state, action) => {
			state.data = action.payload;
		},
	},
	extraReducers: {
		[fetchProgress.fulfilled]: (state, action) => {
			const { currentProgress, modules, lessons } = action.payload;
			if (Object.keys(currentProgress).length === 0) {
				notificationToast(
					'Az aktuális félévben számodra még nem érhetőek el a tananyagok. Látogass vissza később.'
				);
			}
			state.currentProgress = currentProgress;
			state.modules = modules;
			state.lessons = lessons;
		},
	},
});

export const { setProgress } = progressSlice.actions;

export const selectProgress = ({ progress: { currentProgress, modules, lessons } }) => ({
	currentProgress,
	isDataAvailable: Object.keys(currentProgress).length > 0,
	moduleProgression:
		currentProgress?.moduleProgresses?.filter(({ status }) => status === COMPLETED).length ?? 0,
	modules,
	lessons,
});

export const selectProgressStatus = ({ progress: { currentProgress } }) => ({
	isStatusAvailable: Object.keys(currentProgress).length > 0,
	status: currentProgress?.status,
});

export const getProgressType = ({ progress: { currentProgress } }) => {
	if (!currentProgress.moduleProgresses) {
		return null;
	}

	const firstModule = currentProgress.moduleProgresses[0];
	if (firstModule.status !== 'COMPLETED') {
		return 'zeroModulesCompleted';
	}

	const lastModule =
		currentProgress.moduleProgresses[currentProgress.moduleProgresses.length - 1];
	return lastModule.status === 'COMPLETED' ? 'allModulesCompleted' : 'someModulesCompleted';
};

export default progressSlice.reducer;
