import { URL_PREFIXES } from 'app/constants';
import { AuthLayout } from 'app/layouts';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const authRoutes = {
	path: URL_PREFIXES.AUTH,
	component: AuthLayout,
	routes: [
		{
			path: `${URL_PREFIXES.AUTH}/login`,
			exact: true,
			component: lazy(() => import('features/auth/login')),
		},
		{
			path: `${URL_PREFIXES.AUTH}/forgot-password`,
			exact: true,
			component: lazy(() => import('features/auth/forgot-password')),
		},
		{
			path: `${URL_PREFIXES.AUTH}/forgot-password/success`,
			exact: true,
			component: lazy(() => import('features/auth/forgot-password/success')),
		},
		{
			path: `${URL_PREFIXES.AUTH}/change-password/success`,
			exact: true,
			component: lazy(() => import('features/auth/change-password/success')),
		},
		{
			path: `${URL_PREFIXES.AUTH}/change-password/failed`,
			exact: true,
			component: lazy(() => import('features/auth/change-password/failed')),
		},
		{
			path: `${URL_PREFIXES.AUTH}/change-password/:token`,
			exact: true,
			component: lazy(() => import('features/auth/change-password')),
		},
		{
			path: `${URL_PREFIXES.AUTH}/register/success`,
			exact: true,
			component: lazy(() => import('features/auth/register/success')),
		},
		{
			path: `${URL_PREFIXES.AUTH}/register`,
			exact: true,
			component: lazy(() => import('features/auth/register')),
		},
		{
			path: `${URL_PREFIXES.AUTH}/register-office/success`,
			exact: true,
			component: lazy(() => import('features/auth/register-office/success')),
		},
		{
			path: `${URL_PREFIXES.AUTH}/register-office/failed`,
			exact: true,
			component: lazy(() => import('features/auth/register-office/failed')),
		},
		{
			path: `${URL_PREFIXES.AUTH}/register-office/:token`,
			exact: true,
			component: lazy(() => import('features/auth/register-office')),
		},
		{
			path: `${URL_PREFIXES.AUTH}/verify/success`,
			exact: true,
			component: lazy(() => import('features/auth/verify/success')),
		},
		{
			path: `${URL_PREFIXES.AUTH}/verify/failed`,
			exact: true,
			component: lazy(() => import('features/auth/verify/failed')),
		},
		{
			path: `${URL_PREFIXES.AUTH}/verify/:token`,
			exact: true,
			component: lazy(() => import('features/auth/verify')),
		},
		{
			component: () => <Redirect to="/errors/error-404" />,
		},
	],
};

export default authRoutes;
